// import { Routes } from './router'

// function App() {
//   return <Routes />
// }

function App() {
  return (
    <main style={{ ...styles.container }}>
      <div>
        <h1 style={{ ...styles.text }}>Site em manutenção</h1>
      </div>
    </main>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  text: {
    color: 'white',
    fontSize: '20px',
  },
}

export default App
