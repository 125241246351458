import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
body, html {
  height: 100%;

    font-family: 'Oswald', sans-serif;
    /* background-color: #f2f2f2; */
    background-color: #23222f;
    -webkit-font-smoothing: antialiased;
  }
`;
